import { getTimezone } from '@/services/Api';
import moment from 'moment';
import { AccountingDocumentStatus, DocumentType, PaymentType, PaymentStatus, CreditCardPaymentType, InvoiceType } from './Enums';
import {
    BusinessCashPayment,
    BusinessCheckPayment,
    BusinessBankTransferPayment,
    BusinessCreditCardPelecardPayment
} from '@/models/BusinessClients';

export class BaseAccountingDocument {
    baseAccountingDocumentId = '';
    recipientName = '';
    orderId = '';
    documentNumber = 0;
    providerId = 0;
    remarks = '';
    total = 0;
    currencyId = '';
    currencyRate = 1;
    localCurrencyId = '';
    localCurrencyRate = 1;
    localCurrencyTotalAmount = 0;
    status: AccountingDocumentStatus = AccountingDocumentStatus.None;
    isOriginalPrinted = false;
    for = '';
    customerId = '';
    documentType: DocumentType | null = null;
    dateOfPrint: Date | null = null;
    issueDate: Date | null = null;
    cancelDate: Date | null = null;
}

export class ReceiptDocument extends BaseAccountingDocument {
    // baseAccountingDocumentId = '';
}

export class BasePayment {
    basePaymentId = '';
    totalAmount = 0;
    currencyId = '';
    localCurrencyId = 0;
    localCurrencyRate = 1;
    localCurrencyTotalAmount = 0;
    paymentDate: Date | null = null;
    orderId = '';
    receiptDocumentId = '';
    receiptDocument = new ReceiptDocument();
    paymentType: PaymentType | null = null;
    customerId = '';
    status: PaymentStatus = PaymentStatus.None;
}

export class BankTransferPayment {
    basePaymentId = '';
    basePayment = new BasePayment();
    bankId = '';
    bankBranchNumber: number | null = null;
    accountNumber = '';
    referenceNumber = 0;
}

export class CashPayment {
    basePaymentId = '';
    basePayment = new BasePayment();
}

export class CheckPayment {
    basePaymentId = '';
    basePayment = new BasePayment();
    bankId = '';
    bankBranchNumber: number | null = null;
    accountNumber = '';
    checkNumber = 0;
}

export class CreditCardPayment extends BasePayment {
    //basePaymentId = '';
    // basePayment = new BasePayment();
    numberOfPayments = 1;
    creditCardPaymentType = CreditCardPaymentType.Regular;
    holderFullName = '';
    holderIdNumber = '';
    lastFourDigits = '';
}

export class Bank {
    id = '';
    name = '';
    code = '';
    active = true;
}

export class CreditCardReceipt {
    orderId = '';
    total = 0;
    currencyId = '';
    localCurrencyRate = 1;
    for = '';
    recipientName = '';
    numberOfPayments = 1;
    holderFullName = '';
    holderIdNumber = '';
    lastFourDigits = '';
    timezone: number = getTimezone();
}

export class CashReceipt {
    orderId = '';
    total = 0;
    currencyId = '';
    localCurrencyRate = 1;
    for = '';
    recipientName = '';
    valueDate: Date = moment().toDate();
    timezone: number = getTimezone();
}

export class BankTransferReceipt {
    orderId = '';
    total = 0;
    currencyId = '';
    localCurrencyRate = 1;
    for = '';
    recipientName = '';
    valueDate: Date = moment().toDate();
    accountNumber = '';
    bankId = '';
    bankBranchNumber: number | null = null;
    referenceNumber = '';
    timezone: number = getTimezone();
}

export class CheckReceiptPayment {
    valueDate: Date = moment().toDate();
    owner = '';
    accountNumber = '';
    bankId = '';
    bankBranchNumber: number | null = null;
    checkNumber = '';
    amount = 0;
    timezone: number = getTimezone();
}

export class CheckReceipt {
    orderId = '';
    total = 0;
    currencyId = '';
    localCurrencyRate = 1;
    for = '';
    recipientName = '';
    payments: CheckReceiptPayment[] = [new CheckReceiptPayment()];
    timezone: number = getTimezone();
}

export class Invoice {
    orderId = '';
    orderNumber = 0;
    documentNumber = '';
    extraAmount = 0;
    totalAmount = 0;
    vatPercent = 0;
    vatAmount = '';
    discountAmount = 0;
    discountPercent = 0;
    productsTotalAmount = '';
    currencyId = '';
    for = '';
    recipientName = '';
    products: InvoiceDocumentProduct[] = [];
    timezone: number = getTimezone();
    type: InvoiceType = 1;
    pdfUrl = '';
}

export class InvoiceDocumentProduct {
    id = '';
    total = 0;
    discount = 0;
    pricePerUnit = '';
    quantity = '';
    unitName = '';
    productId = null;
    invoiceDocumentId = '';
    name = '';
    currencyId = '';
    currencySign = '';
    localCurrencyRate = 0;
}

export class BusinessInvoice {
    id = '';
    orderId = '';
    orderNumber = 0;
    discountAmount = 0;
    discountPercent = 0;
    productsTotalAmount = '';
    currencyId = '';
    products: InvoiceDocumentProduct[] = [];
    timezone: number = getTimezone();
    businessClientName = '';
    businessClientId = 0;
    extraAmount: number | null = null;
    createdDate = '';
    issueDate = '';
    recipientName = '';
    documentNumber: number | string | null = null;
    customers: BusinessInvoiceCustomer[] = [];
    type: InvoiceType;
    for = '';
    customersTotalAmount = '';
    currencySymbol = '';
    totalAmount = 0;
    vatPercent = 0;
    vatAmount = '';
    customersAmountInLocal = 0;
    pdfUrl = '';
    includeVat = false;
    constructor(type: InvoiceType) {
        this.type = type;
    }
}
export class InvoiceReceipt {
    totalAmount = 0;
    vatPercent = 0;
    vatAmount = '';
    recipientName = '';
    orderId = '';
    for = '';
    createdDate = '';
    pdfUrl = '';
    totalExcludedVat = '';
    includeVat = false;
    documentNumber: number | null = null;
    timezone: number = getTimezone();
    creditCardPelecardPayments: BusinessCreditCardPelecardPayment[] = [];
    cashPayments: BusinessCashPayment[] = [];
    checkPayments: BusinessCheckPayment[] = [];
    bankTransferPayments: BusinessBankTransferPayment[] = [];
}
export class Receipt {
    documentNumber: number | null = null;
    recipientName = '';
    for = '';
    businessClientName = '';
    businessClientId = 0;
    createdDate = '';
    invoices: InvoiceForReceipt[] = [];
    invoicesTotalAmount = 0;
    creditCardPelecardPayments: BusinessCreditCardPelecardPayment[] = [];
    cashPayments: BusinessCashPayment[] = [];
    checkPayments: BusinessCheckPayment[] = [];
    bankTransferPayments: BusinessBankTransferPayment[] = [];
    timezone: number = getTimezone();
    pdfUrl = '';
    pdfUrlWithInvoices = '';
    orderId = '';
}
export class InvoiceForReceipt {
    baseAccountingDocumentId = '';
    documentNumber: number | null = null;
    for = '';
    amount = 0;
    leftToCoverInLocal = 0;
    amountCovered = 0;
    createdDate = '';
    creator = '';
    amountToCover = 0;
}

export class BusinessInvoiceCustomer {
    id = '';
    orderId = '';
    productId = '';
    orderNumber: number | null = null;
    productName = '';
    couponName = '';
    couponId = '';
    customerTypeName = '';
    customerName = '';
    amount = 0;
    amountInLocal = 0;
    coverByInvoices = 0;
    coverByInvoicesInLocal = 0;
    leftToCoverInvoice = 0;
    leftToCoverInvoiceInLocal = 0;
    currencyRate = 0;
    amountToCover = 0;
    amountToCoverInLocal = 0;
    localCurrencyRate = 0;
    currencySymbol = '';
    localCurrencySymbol = '';
    createdDate = '';
    arrivalDate = '';
    currencyId = 0;
}

export class BulkInvoicesInfo {
    id = '';
    qtyOfInvoices = 0;
    amountOfInvoices = 0;
    startDate = '';
    endDate = '';
    pdfReportUrl = '';
}
