
import { defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'OverlayModal',
    props: {
        isOpen: Boolean,
        contentClass: {
            type: [String, Object]
        },
        closeOnClick: {
            type: Boolean,
            default: false
        },
        modalWidth: {
            type: [Number, String],
            default: 470
        },
        modalPadding: {
            type: [Number, String],
            default: 16
        }
    },
    emits: ['close', 'update:isOpen'],
    async setup(props, { emit }) {
        const back = () => {
            emit('close');
            emit('update:isOpen', false);
        };
        const sizes = computed(() => {
            let width = String(props.modalWidth);
            if (!isNaN(Number(width))) {
                width += 'px';
            }
            let padding = String(props.modalPadding);
            if (!isNaN(Number(padding))) {
                padding += 'px';
            }
            const padding2 = padding.replace(/\d+/g, x => String(Number(x) * 2));

            return {
                padding,
                width,
                max: `calc(${width} + ${padding2})`
            };
        });
        return {
            back,
            open,
            sizes
        };
    }
});
