
/* eslint-disable no-undef */
import { api } from '@/services/Api';
import { defineComponent, ref, computed } from 'vue';
import router from '@/router';
import { generalStore } from '@/store';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import NotificationMenu from '@/components/NotificationMenu.vue';
import { OrdersFilter } from '@/models/Order';
import moment from 'moment';

export default defineComponent({
    computed: {
        currentPagename() {
            return this.$route.name;
        }
    },
    name: 'NavBar',
    components: {
        NotificationMenu
    },
    async setup() {
        const isExpandMenu = ref(false);
        const { t, locale } = useI18n();
        const scrolled = ref(false);
        const showCashier = computed(() => generalStore.state.showCashier);
        const logout = async () => {
            const response = await api.logout();
            if (response.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
            } else {
                generalStore.commit('logout');
                router.push('/login');
            }
        };

        function changeNotificationCondition(status: boolean) {
            generalStore.commit('updateNotificationCondition', status);
        }
        const body = document.querySelector('body');
        const html = document.querySelector('html');
        async function changeLang(lang: string): Promise<void> {
            isExpandMenu.value = false;
            await api.changeLanguage(lang);
            if (html && body) {
                const dir = lang === 'he-IL' ? 'rtl' : 'ltr';
                body.setAttribute('dir', dir);
                html.setAttribute('lang', lang);
                window.location.reload();
                if (!body.classList.contains(dir)) {
                    body.classList.add(dir);
                    const removeClass = lang === 'he-IL' ? 'ltr' : 'rtl';
                    body.classList.remove(removeClass);
                }
            }
            generalStore.commit('updateLang', lang);
            locale.value = generalStore.getters.currentLanguage;
        }

        const reloadCurrentPage = (route: string) => {
            if (router.currentRoute.value.name == route) {
                router.go(0);
                return;
            }
        };
        return {
            reloadCurrentPage,
            scrolled,
            isExpandMenu,
            logout,
            changeLang,
            generalStore,
            locale,
            changeNotificationCondition,
            showCashier
        };
    }
});
